import * as PIXI from "pixi.js";
import { DropShadowFilter } from "@pixi/filter-drop-shadow";

import gsap from "gsap";

export default class PillLabel {
  constructor(text) {
    this.pixi = new PIXI.Container();

    this.text = new PIXI.Text(text);
    this.text.style = new PIXI.TextStyle({
      fontFamily: "Nunito Sans",
      fill: "#0x3c4856",
      fontSize: 12,
    });

    this.pixi.addChild(this.text);

    

    const w = this.text.width;

    this.pill = new PIXI.Graphics();
    this.pill.beginFill(0xffffff);

    this.pill.lineStyle(1, 0xd5402b, 0);
    this.pill.drawCircle(0, 10, 10);
    this.pill.drawCircle(w, 10, 10);
    this.pill.drawRect(0, 0, w, 20);
    this.pill.endFill();

    this.pixi.addChild(this.pill);
    this.pixi.addChild(this.text);

    this.text.x = 0;
    this.text.y = 2;

    const dropShadowFilter = new DropShadowFilter();
    dropShadowFilter.color = 0x000;
    dropShadowFilter.alpha = 0.2;
    dropShadowFilter.blur = 1;
    dropShadowFilter.distance = 0;
    dropShadowFilter.quality = 3;

    this.pill.filters = [dropShadowFilter];
    dropShadowFilter;
  }

  set(label) {
    this.text.text = label;
    const w = this.text.width;

    this.pill.clear();
    this.pill.beginFill(0xffffff);

    this.pill.lineStyle(1, 0xd5402b, 0);
    this.pill.drawCircle(0, 10, 10);
    this.pill.drawCircle(w, 10, 10);
    this.pill.drawRect(0, 0, w, 20);
    this.pill.endFill();

    gsap.to(this.pixi,{alpha:0,duration:0,overwrite:true});
    gsap.to(this.pixi,{alpha:1,delay:1});
  }
}
