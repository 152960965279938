<template>
  <div class="range-slider">
    <div class="debug">{{ percent }}<br />step: {{ step }}</div>
    <div class="range-slider__container" ref="container">
      <div
        class="range-slider__knob"
        :style="{ left: knobPos + 'px' }"
        ref="knob"
        @mousedown="onMouseDown"
        @touchstart="onTouchStart"
      ></div>
      <div class="range-slider__rail" ref="rail" @click="onRailClick">
        <div class="range-slider__rail-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "RangeSlider",
  props: {
    id: undefined,
    steps: undefined
  },
  data() {
    return {
      sliderSize: 10,
      step: 0,
      touchPos: undefined,
      knobPos: undefined,
      maxRange: undefined,
      percent: 0
    };
  },
  methods: {
    randomize() {
      if (!this.maxRange) this.setMaxRange();
      let pos = this.maxRange * Math.random();
      gsap.to(this, { duration: 0.3 + 0.2 * Math.random(), knobPos: pos });
    },
    onRailClick(e) {
      if (!this.maxRange) this.setMaxRange();
      let pos = e.clientX - this.$refs.container.getBoundingClientRect().x;
      gsap.to(this, { duration: 0.3, knobPos: pos });
    },
    setMaxRange() {
      this.maxRange = this.$refs.rail.getBoundingClientRect().width;
    },
    storePosition(x) {
      this.touchPos = x;
    },
    setPosition(x) {
      x = x - this.$refs.container.getBoundingClientRect().x;
      this.setMaxRange();

      let pos = x - this.sliderSize / 2;

      if (pos < 0) pos = 0;
      if (pos > this.maxRange) pos = this.maxRange;

      this.knobPos = pos;
    },
    onTouchStart(e) {
      this.storePosition(e.touches[0].clientX);
      window.addEventListener("touchmove", this.onTouchMove);
      window.addEventListener("touchend", this.onTouchEnd);
    },
    onTouchEnd() {
      window.removeEventListener("touchmove", this.onTouchMove);
      window.removeEventListener("touchend", this.onTouchEnd);
    },
    onTouchMove(e) {
      this.setPosition(e.touches[0].clientX);
    },
    onMouseDown(e) {
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
      this.storePosition(e.clientX);
    },
    onMouseUp() {
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
    },
    onMouseMove(e) {
      this.setPosition(e.clientX);
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },

  watch: {
    stageWidth() {
      this.setMaxRange();
      this.knobPos = this.maxRange * this.percent;
    },

    step() {
      this.$emit("change", {
        id: this.id,
        step: this.step,
        percent: this.percent
      });
    },
    knobPos() {
      this.percent = this.knobPos / this.maxRange;

      if (this.steps) {
        const step = 1 + Math.round(this.percent * (this.steps - 1));
        this.step = step;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("touchend", this.onTouchEnd);
  },
  mounted() {
    this.setPosition(0);
  }
};
</script>
