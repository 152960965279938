<template>
  <div class="decor-interaction">
    <div class="decor-interaction__stage" ref="stage"></div>
    <h1>Of which mood are you minded?</h1>
    <div class="decor-interaction__controls-toggle" @click="toggleControls">
      <inline-svg :src="require('../../../assets/svg/icon_hotspot.svg')" />
    </div>
    <div class="decor-interaction__controls" ref="controls">
      <h1>Toggle Mountain Range</h1>

      <div class="decor-interaction__slider-container">
        <RangeSlider
          ref="slider1"
          :id="1"
          :steps="3"
          @change="onRangeSliderChange"
        />
        <RangeSlider
          ref="slider2"
          :id="2"
          :steps="3"
          @change="onRangeSliderChange"
        />
        <RangeSlider
          ref="slider3"
          :id="3"
          :steps="3"
          @change="onRangeSliderChange"
        />
        <RangeSlider
          ref="slider4"
          :id="4"
          :steps="3"
          @change="onRangeSliderChange"
        />

        <div class="decor-interaction__buttons">
          <Button class="button--small" @click.native="onRandomizeClick()"
            >RANDOMIZE</Button
          >
          <Button class="button--small" @click.native="onDownloadClick()"
            >DOWNLOAD</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PillLabel from "./ui/PillLabel";
import * as PIXI from "pixi.js";
import gsap from "gsap";
import Button from "../../form/Button";
import RangeSlider from "../../form/RangeSlider.vue";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";

export default {
  name: "DecorInteraction",
  components: { Button, RangeSlider },
  mixins: [DownloadHelperMixin],
  data() {
    return {
      pixiApp: undefined,
      pixiContainer: undefined,
      currentPos: { x: 0, y: 0 },
      targetPos: { x: 0, y: 0 },

      app: undefined,

      base: undefined,

      layer0: undefined,
      layer1: undefined,
      layer2: undefined,
      layer3: undefined,
      layer4: undefined,
      layer5: undefined,
      layer6: undefined,

      image0: undefined,
      image1: undefined,
      image2: undefined,
      image3: undefined,
      image4: undefined,
      image5: undefined,
      image6: undefined,

      sceneSprites: [],
      sprite0: undefined,
      sprite1: undefined,
      sprite2: undefined,
      sprite3: undefined,
      sprite4: undefined,
      sprite5: undefined,
      sprite6: undefined,

      label1: undefined,
      label2: undefined,
      label3: undefined,
      label4: undefined,

      timer: 0,
      rotateValue: 0,
      activeSet: 1,
      groundY: 0,
      controlsToggled: false
    };
  },
  methods: {
    toggleControls() {
      this.controlsToggled = !this.controlsToggled;

      if (this.controlsToggled) {
        gsap.to(this.$refs.controls, { duration: 0.5, autoAlpha: 1 });
        gsap.to(this.$refs.controls, {
          ease: "Expo.easeOut",
          duration: 0.8,
          y: 0
        });
      } else {
        gsap.to(this.$refs.controls, { duration: 0.25, y: 20, autoAlpha: 0 });
      }
    },
    onDownloadClick() {
      this.pixiResize(true);

      this.createPngTextureFromPixi(
        this.app,
        "MountainRange.png",
        this.app.stage,
        true,
        { w: 1920, h: 1315 }
      );

      this.pixiResize(false);
    },
    onRandomizeClick() {
      this.$refs.slider1.randomize();
      this.$refs.slider2.randomize();
      this.$refs.slider3.randomize();
      this.$refs.slider4.randomize();
    },
    setDecorLabels(slider) {
      const labels1 = [
        "60159 Niobe Beige",
        "60172 Galovac",
        "60155 Infinity Brown 9022911"
      ]; //EBENE5
      const labels2 = [
        "71123 Kilox 9018713",
        "60161 Tivoli 9024169",
        "60161 Tivoli 9024166"
      ]; //EBENE3
      const labels3 = [
        "39510 Pacific Pine",
        "71150 Acadia 9026526",
        "32356 Samdal Oak 9024814_FF"
      ]; //EBENE2
      const labels4 = [
        "71145 Tapiz 9023463 ",
        "30188 BoyleAcacia 9026980",
        "32353 Boudica Oak 9023275"
      ]; //EBENE1
      /*

        V01_Ebene00	32376_Mauvella_Oak_9025511_FF
V02_Ebene00	32390_Adare_Oak_9027923
V03_Ebene00	60173_Santanoni_Slate

V01_Ebene1	71145_Tapiz_9023463 
V02_Ebene1	30188_Boyle_Acacia_9026980 
V03_Ebene1	32353_Boudica_Oak_9023275 

V01_Ebene2	39510_Pacific_Pine
V02_Ebene2	71150_Acadia_9026526
V03_Ebene2	32356_Samdal_Oak_9024814_FF

V01_Ebene3	71123_Kilox_9018713 
V02_Ebene3	60161_Tivoli_9024169
V03_Ebene3	60161_Tivoli_9024166
	
V01_Ebene5	60159_Niobe_Beige
V02_Ebene5	60172_Galovac
V03_Ebene5	60155_Infinity_Brown_9022911

*/
      if (slider.id > 1) {
        if (slider.id === 2) {
          this.label1.set(labels1[slider.step - 1]);
        }
        if (slider.id === 3) this.label2.set(labels2[slider.step - 1]);
        if (slider.id === 4) {
          //this.label3.set(labels3[slider.step-1]);
          //this.label4.set(labels4[slider.step-1]);
          this.label3.set(labels3[slider.step - 1]); //ebene2
          this.label4.set(labels4[slider.step - 1]); //ebene1
        }
      }
    },
    onRangeSliderChange(slider) {
      this.setDecorLabels(slider);
      
      if (slider.id === 1) {
        //background
        let background = this.sceneSprites[slider.step - 1][0];
        if (!background) {
          const path =
            "/assets/textures/mountain/V0" + slider.step + "_Ebene0.jpg";
          background = PIXI.Sprite.from(path);

          this.sceneSprites[slider.step - 1][0] = background;
        } else {
          background = this.sceneSprites[slider.step - 1][0];
        }

        this.layer0.addChild(background);
        gsap.set(background, { alpha: 0 });
        gsap.to(background, { alpha: 1 });
      } else if (slider.id === 2) {
        //gsap.to(this.image3,{duration:1,rotation:slider.percent})

        this.image2 = this.swapSprite(
          this.image2,
          2,
          "_Ebene5.png",
          this.layer2,
          1,
          slider.step
        );
      } else if (slider.id === 3) {
        this.image4 = this.swapSprite(
          this.image4,
          4,
          "_Ebene3.png",
          this.layer4,
          1,
          slider.step
        );

        this.onSliderChange(slider.step);
      } else if (slider.id === 4) {
        this.image5 = this.swapSprite(
          this.image5,
          5,
          "_Ebene2.png",
          this.layer5,
          1,
          slider.step
        );
        this.image6 = this.swapSprite(
          this.image6,
          6,
          "_Ebene1.png",
          this.layer6,
          1.1,
          slider.step
        );
      }
      /*
      this.image2 = this.swapSprite(
        this.image2,
        2,
        "_Ebene5.png",
        this.layer2,
        1
      );
      this.image4 = this.swapSprite(
        this.image4,
        4,
        "_Ebene3.png",
        this.layer4,
        1
      );
      this.image5 = this.swapSprite(
        this.image5,
        5,
        "_Ebene2.png",
        this.layer5,
        1
      );
      this.image6 = this.swapSprite(
        this.image6,
        6,
        "_Ebene1.png",
        this.layer6,
        1
      );*/
      //this.onSliderChange(step);
    },
    swapSprite(oldImage, index, asset, container, duration, step) {
      let useStep = this.activeSet;
      if (step) useStep = step;

      let image = this.sceneSprites[useStep - 1][index];

      if (!image) {
        const path = "/assets/textures/mountain/V0" + useStep + asset;
        //console.log("CREATING NEW IMAGE" + index + ": " + path);
        image = PIXI.Sprite.from(path);
        image.anchor.set(0.5, 1);
        image.x = 1920 / 2;
        image.y = this.groundY;

        this.sceneSprites[useStep - 1][index] = image;
      } else {
        //console.log("CHANGING IMAGE4.");
        image = this.sceneSprites[useStep - 1][index];
      }

      //this.layer4.removeChild(this.layer4.children[0]);
      container.addChild(image);

      gsap.to(oldImage, {
        ease: "Expo.easeOut",
        alpha: 0,
        duration: duration,
        rotation: -Math.PI
      });

      gsap.set(image, { rotation: Math.PI });
      gsap.to(image, {
        ease: "Expo.easeOut",
        duration: duration,
        delay: 0,
        rotation: 0,
        alpha: 1
      });

      return image;
    },
    onSliderChange(set) {
      this.activeSet = parseInt(set);

      let moon = this.sceneSprites[this.activeSet - 1][1];
      if (!moon) {
        const path =
          "/assets/textures/mountain/V0" + this.activeSet + "_Mond.png";

        moon = PIXI.Sprite.from(path);

        this.sceneSprites[this.activeSet - 1][1] = moon;
      } else {
        moon = this.sceneSprites[this.activeSet - 1][1];
      }

      moon.anchor.set(0.5);
      moon.x = 600;
      moon.y = 480;

      gsap
        .to(this.layer1, { overwrite: true, alpha: 0, duration: 0.2, y: 350 })
        .then(() => {
          this.layer1.removeChild(this.layer1.children[0]);
          this.layer1.addChild(moon);
        });

      gsap.to(this.layer1, {
        ease: "Expo.easeOut",
        delay: 0.5,
        duration: 0.8,
        y: 0,
        alpha: 1
      });
    },
    pixiResize(download) {
      const rect = this.$refs.stage.getBoundingClientRect();
      this.rect = rect;
      let w = rect.width;
      let h = rect.height;

      if (download) {
        w = 1920;
        h = 1315;
      }

      // resize the renderer
      this.app.renderer.view.style.width = w + "px";
      this.app.renderer.view.style.height = h + "px";
      this.app.renderer.resize(w, h);

      if (this.layer2) {
        let scale = w / 2100; //1920 scaledown

        const minScale = 0.25;
        //const maxScale = 1;

        if (scale < minScale) scale = minScale;
        //if (scale > maxScale) scale = maxScale;

        this.pixiContainer.pivot.x = 0;
        this.pixiContainer.pivot.y = 0;

        this.pixiContainer.scale.x = scale;
        this.pixiContainer.scale.y = scale;

        this.pixiContainer.x = w * 0.5 - (1920 / 2) * scale;
        this.pixiContainer.y = h - 1200 * scale;

        const ratio = h / w;
        //console.log(ratio);
        if (ratio > 0.8) {
          //portrait
          this.pixiContainer.y = h * 0.8 - 1200 * scale;
        }
        //console.log(this.pixiContainer.height);

        let bgScale = window.innerWidth / 1920;

        this.layer0.scale.x = bgScale;
        this.layer0.scale.y = bgScale;

        if (h > this.layer0.height) {
          bgScale = h / 1315;

          this.layer0.scale.x = bgScale;
          this.layer0.scale.y = bgScale;
        }
      }
    },
    initScene() {
      //Create a Pixi Application
      let app = new PIXI.Application({
        width: 1920,
        height: 1315,
        antialias: true
      });

      app.stage.interactive = true;
      app.stage.on("pointermove", this.onMouseMove);

      this.app = app;
      this.$refs.stage.appendChild(app.view);

      //LOADING
      this.loader = new PIXI.Loader(); // you can also create your own if you want

      const sets = 3;
      const layers = [
        "Ebene0",
        "Ebene1",
        "Ebene2",
        "Ebene3",
        "Ebene4",
        "Ebene5",
        "Mond"
      ];
      this.sceneSprites[0] = [];
      for (var i = 1; i < sets; i++) {
        this.sceneSprites[i] = [];

        layers.forEach(id => {
          let suffix = ".png";
          if (id === "Ebene0") suffix = ".jpg";
          const file =
            "/assets/textures/mountain/V0" + (i + 1) + "_" + id + suffix;

          this.loader.add("V0" + (i + 1) + "_" + id + suffix, file);
        });
      }

      this.loader.load((loader, resources) => {
        loader, resources;
      });

      // throughout the process multiple signals can be dispatched.
      this.loader.onProgress.add(() => {}); // called once per loaded/errored file
      this.loader.onError.add(() => {}); // called once per errored file
      this.loader.onLoad.add(() => {}); // called once per loaded file
      this.loader.onComplete.add(() => {
        gsap.delayedCall(0.01, this.showStage);
      }); // called once when the queued resources all load.

      const img0 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene0.jpg"
      );
      const img1 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Mond.png"
      );
      const img2 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene5.png"
      );
      const img3 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene4.png"
      );
      const img4 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene3.png"
      );
      const img5 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene2.png"
      );
      const img6 = PIXI.Sprite.from(
        "/assets/textures/mountain/V0" + this.activeSet + "_Ebene1.png"
      );

      //img1.anchor.set(.5);
      img1.anchor.set(0.5);
      img2.anchor.set(0.5, 1);
      img3.anchor.set(0.5, 1);
      img4.anchor.set(0.5, 1);
      img5.anchor.set(0.5, 1);
      img6.anchor.set(0.5, 1);

      this.sceneSprites[this.activeSet - 1][0] = img0;
      this.sceneSprites[this.activeSet - 1][1] = img1;
      this.sceneSprites[this.activeSet - 1][2] = img2;
      this.sceneSprites[this.activeSet - 1][3] = img3;
      this.sceneSprites[this.activeSet - 1][4] = img4;
      this.sceneSprites[this.activeSet - 1][5] = img5;
      this.sceneSprites[this.activeSet - 1][6] = img6;

      const container0 = new PIXI.Container();
      container0.addChild(img0);

      const container1 = new PIXI.Container();
      container1.addChild(img1);

      const container2 = new PIXI.Container();
      container2.addChild(img2);

      const container3 = new PIXI.Container();
      container3.addChild(img3);

      const container4 = new PIXI.Container();
      container4.addChild(img4);

      const container5 = new PIXI.Container();
      container5.addChild(img5);

      const container6 = new PIXI.Container();
      container6.addChild(img6);

      this.layer0 = container0;
      this.layer1 = container1;
      this.layer2 = container2;
      this.layer3 = container3;
      this.layer4 = container4;
      this.layer5 = container5;
      this.layer6 = container6;

      this.pixiContainer = new PIXI.Container();

      //this.pixiContainer.addChild(this.layer0);
      this.pixiContainer.addChild(this.layer1);
      this.pixiContainer.addChild(this.layer2);
      this.pixiContainer.addChild(this.layer3);
      this.pixiContainer.addChild(this.layer4);
      this.pixiContainer.addChild(this.layer5);
      this.pixiContainer.addChild(this.layer6);

      this.app.stage.addChild(this.layer0);
      this.app.stage.addChild(this.pixiContainer);

      const groundY = 1100;
      this.groundY = 1100;

      img2.x = 1920 / 2;
      img2.y = groundY;

      img3.x = 0;
      img3.y = 0;

      img4.x = 1920 / 2;
      img4.y = groundY;

      img5.x = 1920 / 2;
      img5.y = groundY;

      img6.x = 1920 / 2;
      img6.y = groundY;

      this.image0 = img0;
      this.image1 = img1;
      this.image2 = img2;
      this.image3 = img3;
      this.image4 = img4;
      this.image5 = img5;
      this.image6 = img6;

      this.image3.pivot.x = 400;
      this.image3.pivot.y = 600;

      this.image3.x = 1920 / 2;
      this.image3.y = groundY;

      this.label1 = new PillLabel("LABEL1");
      this.label2 = new PillLabel("LABEL2");
      this.label3 = new PillLabel("LABEL3");
      this.label4 = new PillLabel("LABEL4");

      this.pixiContainer.addChild(this.label1.pixi);
      this.pixiContainer.addChild(this.label2.pixi);
      this.pixiContainer.addChild(this.label3.pixi);
      this.pixiContainer.addChild(this.label4.pixi);

      this.label1.pixi.y = 800;
      this.label1.pixi.x = 1560;

      this.label2.pixi.y = 980;
      this.label2.pixi.x = 1500;

      this.label3.pixi.y = 1070;
      this.label3.pixi.x = 1430;

      this.label4.pixi.y = 1070;
      this.label4.pixi.x = 1200;

      this.animate();
      this.pixiResize();
    },

    showStage() {
      this.pixiResize();

      gsap.to(this.$el, {
        delay: 0.1,
        overwrite: true,
        ease: "Expo.easeOut",
        duration: 1,
        scale: 1,
        alpha: 1,
        clearProps: "all"
      });
    },

    animate() {
      this.app.ticker.add(this.onAppTicker);
    },
    onAppTicker() {
      //DING DONG
      //this.rotateValue += 0.00001;
      //if (this.rotateValue >= 1) this.rotateValue = 0;
      //this.image1.rotation = this.rotateValue * 360;
      //this.image4.rotation = this.rotateValue * 360;
      //this.image4.scale.set(1, 1 - this.rotateValue);
    },
    onMouseMove(e) {
      this.currentPos.x = e.data.global.x;
      this.currentPos.y = e.data.global.y;
    }
  },
  watch: {
    stageSize() {
      this.pixiResize();
    }
  },
  computed: {
    stageSize() {
      return this.$screen.lastChange;
    }
  },

  beforeDestroy() {
    this.loader.reset();
    this.loader = undefined;

    this.app.ticker.remove(this.onAppTicker);
    this.app.stage.off("pointermove", this.onMouseMove);
    this.app.destroy();
    this.app = undefined;
  },

  mounted() {
    const rect = this.$refs.stage.getBoundingClientRect();
    this.rect = rect;
    this.initScene();

    gsap.set(this.$el, {
      ease: "Expo.easeOut",
      scale: 1.2,
      alpha: 0,
      clearProps: "all"
    });
  }
};
</script>
